export const ManageVendorsType = {
  PENDING: 0,
  APPROVED: 1,
  RETURNED: 2,
  REJECTED: 3
};

export const manageVendorsColumns = (vue) => {
  return [
    {
      text: vue.$t('admin.businessId'),
      value: '_id',
      width: 250
    },
    {
      text: vue.$t('admin.businessName'),
      value: 'businessName'
    },
    {
      text: vue.$t('vendor.onboarding.businessType'),
      value: 'businessType'
    },
    {
      text: vue.$t('vendor.onboarding.status'),
      value: 'statusText'
    },
    {
      text: vue.$t('vendor.onboarding.primaryCategory'),
      value: 'primaryCategory.name'
    },
    {
      text: vue.$t('vendor.onboarding.secondaryCategory'),
      value: 'secondaryCategories'
    },
    {
      text: '',
      value: 'actions',
      sortable: false
    },
    {
      text: '',
      value: 'nav',
      sortable: false,
      width: 40
    }
  ];
};

export const WorkflowType = {
  ORDER_SUBMISSION: 'order-submission-workflow',
  ORDER_APPROVAL: 'order-approval-workflow',
  ACCOUNT_MANAGEMENT: 'account-management-workflow'
};

export const workflowTypes = (vue) => {
  return [
    {
      text: vue.$t('admin.workflowTypes.orderApprovalWorkflow'),
      value: WorkflowType.ORDER_APPROVAL
    },
    {
      text: vue.$t('admin.workflowTypes.orderSubmissionWorkflow'),
      value: WorkflowType.ORDER_SUBMISSION
    },
    {
      text: vue.$t('admin.workflowTypes.accountManagementWorkflow'),
      value: WorkflowType.ACCOUNT_MANAGEMENT
    }
  ];
};
