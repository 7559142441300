import SubmissionDocType from '@/shared/types/SubmissionDocType';
import SubmissionDocumentStatus from '@/shared/types/SubmissionDocumentStatus';
import {
  mdiInvoiceOutline,
  mdiReceiptOutline,
  mdiInvoiceTextOutline,
  mdiInvoiceArrowLeftOutline
} from '@mdi/js';

export const emptyVendor = {
  _id: '',
  businessName: '',
  businessId: '',
  contactEmail: '',
  contactPhoneNumber: '',
  accountId: '',
  contactPersonName: '',
  primaryCategoryId: '',
  secondaryCategoryId: [],
  description: '',
  type: '',
  street: '',
  city: '',
  cityName: '',
  postalCode: '',
  businessType: '',
  websiteUrl: '',
  facebookPageUrl: '',
  linkedInUrl: '',
  twitterUrl: '',
  instagramUrl: '',
  whatsappPhoneNumber: '',
  accountingBooksCertificationFileId: '',
  accountingBooksCertificationExpirationDate: '',
  taxDeductionAuthorizationFileId: '',
  proofOfBankAccountOwnershipFileId: '',
  businessLicenseFileId: ''
};

Object.freeze(emptyVendor);

export const getDocTypeFilterItems = (vue, vatExpempt = false) => {
  if (!vatExpempt) {
    return [
      {
        text: vue.$t('vendor.submissionDocTypes.transaction-account'),
        value: 'transaction-account'
      },
      {
        text: vue.$t('vendor.submissionDocTypes.invoice'),
        value: 'invoice',
        icon: mdiInvoiceOutline
      },
      {
        text: vue.$t('vendor.submissionDocTypes.invoice-receipt'),
        value: 'invoice-receipt',
        icon: mdiInvoiceTextOutline
      },
      {
        text: vue.$t('vendor.submissionDocTypes.receipt'),
        value: 'receipt',
        icon: mdiReceiptOutline
      },
      {
        text: vue.$t('vendor.submissionDocTypes.credit-invoice'),
        value: 'credit-invoice',
        icon: mdiInvoiceArrowLeftOutline
      }
    ];
  }
};

export const getAllDocTypes = (vue) => {
  return [
    {
      text: vue.$t('vendor.submissionDocTypes.transaction-account'),
      value: 'transaction-account'
    },
    {
      text: vue.$t('vendor.submissionDocTypes.invoice'),
      value: 'invoice',
      icon: mdiInvoiceOutline
    },
    {
      text: vue.$t('vendor.submissionDocTypes.invoice-receipt'),
      value: 'invoice-receipt',
      icon: mdiInvoiceTextOutline
    },
    {
      text: vue.$t('vendor.submissionDocTypes.receipt'),
      value: 'receipt',
      icon: mdiReceiptOutline
    },
    {
      text: vue.$t('vendor.submissionDocTypes.credit-invoice'),
      value: 'credit-invoice',
      icon: mdiInvoiceArrowLeftOutline
    }
  ];
};

export const getParentSubmissionDocTypesForDocTypeByVendor = (
  vue,
  documentType,
  vatExpempt = false
) => {
  const docTypes = getDocTypesForVendor(vue, vatExpempt);
  const filteredParentDoctypes = docTypes.filter((item) =>
    item.children.map((childItem) => childItem.value).includes(documentType)
  );
  return filteredParentDoctypes;
};

export const getDocTypesForVendor = (vue, vatExpempt = false) => {
  if (!vatExpempt) {
    return [
      {
        text: vue.$t('vendor.submissionDocTypes.transaction-account'),
        value: SubmissionDocType.TRANSACTION_ACCOUNT,
        children: [
          {
            text: vue.$t('vendor.submissionDocTypes.invoice-receipt'),
            value: SubmissionDocType.INVOICE_RECEIPT,
            icon: mdiInvoiceTextOutline
          },
          // {
          //   text: vue.$t('vendor.submissionDocTypes.invoice'),
          //   value: SubmissionDocType.INVOICE,
          //   icon: mdiInvoiceOutline
          // },
          // {
          //   text: vue.$t('vendor.submissionDocTypes.receipt'),
          //   value: SubmissionDocType.RECEIPT,
          //   icon: mdiReceiptOutline
          // },
          {
            text: vue.$t('vendor.submissionDocTypes.credit-invoice'),
            value: SubmissionDocType.CREDIT_INVOICE,
            icon: mdiInvoiceArrowLeftOutline
          }
        ]
      },
      {
        text: vue.$t('vendor.submissionDocTypes.invoice'),
        value: SubmissionDocType.INVOICE,
        icon: mdiInvoiceOutline,
        children: [
          {
            text: vue.$t('vendor.submissionDocTypes.receipt'),
            value: SubmissionDocType.RECEIPT,
            icon: mdiReceiptOutline
          },
          {
            text: vue.$t('vendor.submissionDocTypes.credit-invoice'),
            value: SubmissionDocType.CREDIT_INVOICE,
            icon: mdiInvoiceArrowLeftOutline
          }
        ]
      },
      {
        text: vue.$t('vendor.submissionDocTypes.invoice-receipt'),
        value: SubmissionDocType.INVOICE_RECEIPT,
        icon: mdiInvoiceTextOutline,
        children: [
          {
            text: vue.$t('vendor.submissionDocTypes.credit-invoice'),
            value: SubmissionDocType.CREDIT_INVOICE,
            icon: mdiInvoiceArrowLeftOutline
          }
        ]
      }
    ];
  } else {
    return [
      {
        text: vue.$t('vendor.submissionDocTypes.transaction-account'),
        value: SubmissionDocType.TRANSACTION_ACCOUNT,
        children: [
          {
            text: vue.$t('vendor.submissionDocTypes.credit-invoice'),
            value: SubmissionDocType.CREDIT_INVOICE,
            icon: mdiInvoiceArrowLeftOutline
          }
        ]
      }
    ];
  }
};

export const submissionDocTypes = (
  vue,
  vatExpempt = false,
  parentDocType = null,
  docStatus = SubmissionDocumentStatus.OPEN
) => {
  const allDocTypes = getDocTypesForVendor(vue, vatExpempt);
  let docTypes = [];

  if (parentDocType) {
    docTypes =
      allDocTypes.find((item) => item.value === parentDocType)?.children || [];
  } else {
    docTypes = allDocTypes;
  }

  // if document is closed
  // we can return only credit invoice
  if (docStatus === SubmissionDocumentStatus.CLOSED) {
    console.log('doc status closed');
    return docTypes.filter(
      (item) => item.value === SubmissionDocType.CREDIT_INVOICE
    );
  }

  return docTypes;
};
