import { isAfter } from 'date-fns';
import BusinessType from '../types/BusinessType';
import OrderStatus from '../types/OrderStatus';
import OrderSubmissionStatus from '../types/OrderSubmissionStatus';
import RoleType from '../types/RoleType';
import { WorkflowType } from '@/domain/super-admin/constants';
export default {
  computed: {
    businessTypes() {
      return [
        {
          text: this.$t('businessTypes.authorizedDealer'),
          value: BusinessType.AuthorizedDealer
        },
        {
          text: this.$t('businessTypes.limitedCompany'),
          value: BusinessType.Company
        },
        {
          text: this.$t('businessTypes.ngo'),
          value: BusinessType.NGO
        },
        {
          text: this.$t('businessTypes.ngoVat'),
          value: BusinessType.NGOVAT
        },
        {
          text: this.$t('businessTypes.ngoVat'),
          value: BusinessType.NGO
        },
        {
          text: this.$t('businessTypes.exemptDealer'),
          value: BusinessType.ExemptDealer
        },
        {
          text: this.$t('businessTypes.partnership'),
          value: BusinessType.CorporateWithPartnership
        },
        {
          text: this.$t('businessTypes.entityless'),
          value: BusinessType.EntityLess
        },
        {
          text: this.$t('businessTypes.entitylessWithVat'),
          value: BusinessType.EntityLessWithVat
        }
      ];
    },
    orderStatusText() {
      return {
        [OrderStatus.NEW]: this.$t('customer.orders.status.new'),
        [OrderStatus.NEW_PREDICTED]: this.$t(
          'customer.orders.status.new_predicted'
        ),
        [OrderStatus.OPEN_PENDING_VENDOR_REG]: this.$t(
          'customer.orders.status.open_pending_vendor_registration'
        ),
        [OrderStatus.OPEN]: this.$t('customer.orders.status.open'),
        [OrderStatus.CLOSED]: this.$t('customer.orders.status.closed'),
        [OrderStatus.CANCELLED]: this.$t('customer.orders.status.cancelled'),
        [OrderStatus.PENDING_APPROVAL]: this.$t(
          'customer.orders.status.pending_approval'
        ),
        [OrderStatus.RETURNED]: this.$t('customer.orders.status.returned'),
        [OrderStatus.REJECTED]: this.$t('customer.orders.status.rejected')
      };
    },
    orderSubmissionStatusText() {
      return {
        [OrderSubmissionStatus.NEW]: this.$t(
          `vendor.orderSubmissions.status.new`
        ),
        [OrderSubmissionStatus.NEW_PREDICTED]: this.$t(
          `vendor.orderSubmissions.status.new_predicted`
        ),
        [OrderSubmissionStatus.RETURNED]: this.$t(
          `vendor.orderSubmissions.status.returned`
        ),
        [OrderSubmissionStatus.PENDING_AUTHORIZER]: this.$t(
          `vendor.orderSubmissions.status.pending_customer_autorizer`
        ),
        [OrderSubmissionStatus.PENDING_PURCHASER]: this.$t(
          `vendor.orderSubmissions.status.pending_customer_purchaser`
        ),
        [OrderSubmissionStatus.PENDING_APPROVAL]: this.$t(
          `vendor.orderSubmissions.status.pending_approval`
        ),
        [OrderSubmissionStatus.REJECTED]: this.$t(
          `vendor.orderSubmissions.status.rejected`
        ),
        [OrderSubmissionStatus.APPROVED]: this.$t(
          `vendor.orderSubmissions.status.approved`
        )
      };
    },
    approversRoleTypes() {
      return this.roleTypes.filter((item) => item.isApproverRole);
    },
    roleTypes() {
      return [
        {
          text: this.$t('roles.customerAdmin'),
          value: RoleType.CUSTOMER_ADMIN,
          workflowSteps: [],
          isApproverRole: false
        },
        {
          text: this.$t('roles.customerPurchaser'),
          value: RoleType.CUSTOMER_PURCHASER,
          workflowSteps: [
            WorkflowType.ORDER_APPROVAL,
            WorkflowType.ORDER_SUBMISSION
          ],
          isApproverRole: true
        },
        {
          text: this.$t('roles.customerAuthorizer'),
          value: RoleType.CUSTOMER_AUTHORIZER,
          workflowSteps: [
            WorkflowType.ORDER_APPROVAL,
            WorkflowType.ORDER_SUBMISSION
          ],
          isApproverRole: true
        },
        {
          text: this.$t('roles.customerObserver'),
          value: RoleType.CUSTOMER_OBSERVER,
          workflowSteps: [],
          isApproverRole: false
        },
        {
          text: this.$t('roles.customerCatalogAdmin'),
          value: RoleType.CUSTOMER_CATALOG_ADMIN,
          workflowSteps: [],
          isApproverRole: false
        },
        {
          text: this.$t('roles.vendor'),
          value: RoleType.VENDOR_ADMIN,
          workflowSteps: [],
          isApproverRole: false
        },
        {
          text: this.$t('roles.customerAccountManager'),
          value: RoleType.CUSTOMER_ACCOUNT_MANAGER,
          workflowSteps: [WorkflowType.ACCOUNT_MANAGEMENT],
          isApproverRole: true
        }
      ];
    }
  },
  methods: {
    getBusinessTypeText(businessType) {
      if (!businessType) {
        return '';
      }

      return (
        this.businessTypes.find((item) => item.value === businessType)?.text ||
        ''
      );
    }
  }
};
